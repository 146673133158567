import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'lib-application-eligibility',
  templateUrl: './application-eligibility.component.html',
})
export class ApplicationEligibilityComponent {
  @Input() formGroup: FormGroup;
  // only North Dakota uses this step so the text is currently specific to them
  eligibilities = [
    'I have successfully completed a first professional degree in landscape architecture from a program which has been accredited by the Landscape Architecture Accreditation Board (L.A.A.B.).',
    'I have successfully completed two (2) years of diversified experience in landscape architecture per CLARB standards under the direct supervision of a licensed landscape architect.',
    'I have successfully completed all sections the CLARB registration examination where the administration and grading were conducted in accordance with CLARB’s standards in effect at the time.',
  ];

  static GetFormGroup(formBuilder: FormBuilder, alreadyChecked: boolean): FormGroup {
    var formArray = formBuilder.array([]);
    for (var i = 0; i < 3; i++) {
      formArray.push(formBuilder.control(alreadyChecked));
    }
    return formBuilder.group({
      eligibilities: formArray,
    });
  }
}
