import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from 'projects/common/src/lib/services/api/api.service';
import { FirmApplication } from 'projects/common/src/lib/models/applications/firm-application';
import { FirmAvailableApplication } from '../../models/applications/firm-available-application';
import { FirmApplicationFull } from '../../models/applications/firm-application-full';
import { TestInfo } from './architect-application.service';

@Injectable({
  providedIn: 'root',
})
export class FirmApplicationService {
  badApplicationReason: string;

  constructor(private apiService: ApiService) {}

  getPath(firmId, applicationId = ''): string {
    return `firm/${firmId}/applications/${applicationId}`;
  }

  get(firmId: string, applicationId: string): Observable<FirmApplicationFull> {
    let url = this.getPath(firmId, applicationId);
    return this.apiService.get(url).pipe(map(a => new FirmApplicationFull(a)));
  }

  all(firmId: string): Observable<FirmApplication[]> {
    let url = this.getPath(firmId);
    return this.apiService.get(url).pipe(map(data => data.map(a => new FirmApplication(a))));
  }

  async getOrCreate(firmId: string, applicationType: string, licenseType: string): Promise<FirmApplication> {
    return await this.apiService
      .postAsync(this.getPath(firmId), {
        applicationType,
        licenseType,
      })
      .then(a => new FirmApplication(a));
  }

  update = (application: FirmApplication): Promise<FirmApplicationFull> =>
    this.apiService
      .putAsync<FirmApplicationFull>(this.getPath(application.firm.id, application.id), application)
      .then(data => new FirmApplicationFull(data)); //if we don't map data to a FirmApplicationFull, we won't get fields like shortName and lookupId wired up

  updateApplicationStatus = (application: FirmApplication, status: string): Promise<any> =>
    this.apiService.patchAsync(`${this.getPath(application.firmId, application.id)}/status`, {
      ...application,
      status: status,
    });

  updateFeeWaived = (application: FirmApplication, waive: boolean): Promise<any> =>
    this.apiService.patchAsync(`${this.getPath(application.firmId, application.id)}/feeWaived`, waive);

  getAvailable(firmId): Observable<FirmAvailableApplication[]> {
    let url = this.getPath(firmId).concat('available');
    return this.apiService.get(url).pipe(map(data => data.map(a => new FirmAvailableApplication(a))));
  }

  setup = async (firmId, payload): Promise<FirmApplication> =>
    await this.apiService.postAsync<FirmApplication>(this.getPath(firmId).concat('setup'), payload, true);

  getTestOptions = async (firmId): Promise<TestInfo> =>
    await this.apiService.getAsync<TestInfo>(`${this.getPath(firmId)}setup`);
}
