import { UpdatableModel } from '../updatable-model';
import { S3DocumentReference } from '../s3/s3-document-reference';
import { Order } from '../order';
import { LinkInfo } from '../links/link-info';

//application.ts
//  Nothing actually implements this. It's a helper around application
//architect-application.ts new ArchitectApplication
//firm-application-base.ts FirmApplicationBase
//  firm-application-full.ts new FirmApplicationFull
//  firm-application.ts new new FirmApplication
export abstract class ApplicationBase<T> extends UpdatableModel<T> {
  id: string;
  name: string;
  type: string;
  typeAction: string;
  status: string;
  residency: string;
  createdOn: Date;
  submittedOn?: Date;
  approvedOn?: Date;
  isActive: boolean;
  readyForApproval: boolean;
  licenseNumber: string;
  licenseExpirationDate: Date;
  orderId?: string;
  order: Order;
  orderNumber?: number;
  orderStatus?: string;
  orderCompletedOn?: number;
  reviewRoute: string[];
  snapshotDocumentRef: S3DocumentReference;
  applicationVariant: ApplicationVariant;
  isWaivable: boolean;
  feeWaived: boolean;

  get residencyText() {
    return this.residency == 'InState' ? 'In-State' : 'Out-of-State';
  }

  get typeCustomerAction() {
    return this.typeAction == 'Issue' ? 'Apply' : this.typeAction;
  }

  //firmId or personId
  abstract get entityId();
}

export type ApplicationVariant =
  | 'ArchitectInitial'
  | 'ArchitectRenewal'
  | 'ArchitectReinstatement'
  | 'ArchitectReciprocal'
  | 'ArchitectEmeritusRenewal'
  | 'FirmInitial'
  | 'FirmRenewal'
  | 'FirmReinstatement';
