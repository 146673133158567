import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from 'projects/common/src/lib/services/api/api.service';
import { ArchitectApplication } from 'projects/common/src/lib/models/applications/architect-application';
import { ArchitectAvailableApplication } from 'projects/common/src/lib/models/applications/architect-available-application';
import { WorkHistoryForArchApp } from '../../models/work-history-for-arch-app';
import { createManualApplicationParams } from '../../models/applications/create-manual-application-params';
import { Profession } from '../../constants/jurisdiction/profession';
import { LicenseStatus } from '../../components/pill/status-pill.component';

@Injectable({
  providedIn: 'root',
})
export class ArchitectApplicationService {
  badApplicationReason: string;
  constructor(private apiService: ApiService) {}

  getPath(personId, applicationId = ''): string {
    return applicationId ? `person/${personId}/applications/${applicationId}/` : `person/${personId}/applications/`;
  }

  get(personId, applicationId): Observable<ArchitectApplication> {
    return this.apiService.get(this.getPath(personId, applicationId)).pipe(map(a => new ArchitectApplication(a)));
  }

  getWorkHistoryForArchApp(personId, applicationId): Observable<WorkHistoryForArchApp> {
    return this.apiService
      .get(`${this.getPath(personId, applicationId)}workHistoryForArchApp`)
      .pipe(map(a => new WorkHistoryForArchApp(a)));
  }

  all(personId: string): Observable<ArchitectApplication[]> {
    return this.apiService.get(this.getPath(personId)).pipe(map(data => data.map(a => new ArchitectApplication(a))));
  }

  async createManual(personId, createParams: createManualApplicationParams): Promise<ArchitectApplication> {
    return await this.apiService
      .postAsync(`${this.getPath(personId)}manual`, createParams, true)
      .then(a => new ArchitectApplication(a));
  }

  getOrCreate = (personId, applicationType, profession): Promise<ArchitectApplication> =>
    this.apiService
      .postAsync<ArchitectApplication>(this.getPath(personId), {
        applicationType,
        profession,
      })
      .then(json => new ArchitectApplication(json)); //only using the id from the response

  getAvailable(personId): Observable<ArchitectAvailableApplication[]> {
    return this.apiService
      .get(`${this.getPath(personId)}available`)
      .pipe(map(data => data.map(a => new ArchitectAvailableApplication(a))));
  }

  updateApplicationStatus = (application: ArchitectApplication, status: string): Promise<any> =>
    this.apiService.patchAsync(`${this.getPath(application.personId, application.id)}status`, { status });

  updateFeeWaived(application: ArchitectApplication, waive: boolean): Promise<any> {
    return this.apiService.patchAsync(`${this.getPath(application.personId, application.id)}feeWaived`, waive);
  }

  sendInvite(personId: string, applicationId: string): Observable<boolean> {
    return this.apiService.post(`${this.getPath(personId, applicationId)}sendInvite`, {}).pipe(map(_ => true));
  }

  async setup(personId, payload): Promise<any> {
    return await this.apiService.postAsync(`${this.getPath(personId)}setup`, payload);
  }

  async getTestOptions(personId): Promise<Record<Profession, TestInfo>> {
    return await this.apiService.getAsync<Record<Profession, TestInfo>>(`${this.getPath(personId)}setup`);
  }
}

type ActiveRangeType = Extract<LicenseStatus, 'Active' | 'Due' | 'Delinquent' | 'Expired'>;
export interface TestInfo {
  canAddInitial: boolean;
  delinquentDateCurrent: String;
  activeRange: Record<ActiveRangeType, DateRange>; //used for showing the current a license status schedule
  expirationDates: Record<ActiveRangeType, DateRange>; //used for picking a new delinquent date for a license
}

interface DateRange {
  start?: String;
  end?: String;
}
