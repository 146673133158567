import { AuthConfig } from 'angular-oauth2-oidc';

export class NcarbSettings {
  constructor(json: any) {
    if (json.url.endsWith('/')) {
      this.url = json.url;
    } else {
      this.url = `${json.url}/`;
    }
    // by convention
    this.idsrv = this.url.replace('my.ncarb', 'login.ncarb').replace('myncarb.', 'login.');
    this.publicApi = json.publicApi;
    this.auth = {
      loginUrl: `${this.idsrv}connect/authorize`,
      redirectUri: `${window.location.origin}/ncarb`,
      clientId: 'licensing_system',
      scope: 'ncarb_legacy_api',
      userinfoEndpoint: `${this.url}public/api/identity`,
      logoutUrl: `${this.idsrv}connect/endsession`,
      oidc: false,
    };
  }
  url: string;
  publicApi: string;
  idsrv: string;
  auth: AuthConfig;
}
